


































































































































import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";

import BatchRequestModel from "@/application-manager/models/BatchRequestModel";
import ScenarioResponseModel from "@/application-manager/models/ScenarioResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import { AppSection } from "@/shared/models";

@Component({ components: { draggable } })
export default class BatchForm extends Vue {
  @Prop() value!: BatchRequestModel;
  @Prop({ default: "" }) title!: string;

  @Ref("form") readonly form!: VueForm;

  localValue: BatchRequestModel = BatchRequestModel.ofSource(this.value);
  localScenarios: Array<ScenarioResponseModel> = [];
  isValid = false;

  readonly AppSection = AppSection;
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly requiredRuleForScript = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  readonly headers = [
    {
      text: "",
      value: "draggable",
      width: "5%",
    },
    {
      text: this.$lang("applicationManager.name"),
      align: "start",
      value: "name",
      sortable: false,
      width: "25%",
    },
    {
      text: this.$lang("applicationManager.script"),
      align: "start",
      value: "script",
      sortable: false,
      width: "60%",
    },
    {
      text: this.$lang("commonConfig.actions"),
      value: "actions",
      align: "end",
      sortable: false,
      width: "10%",
    },
  ];

  get isSavingInProgress(): boolean {
    return this.$store.state.batchStore.isSavingInProgress;
  }

  get scenarios(): Array<ScenarioResponseModel> {
    return this.$store.state.scenarioStore.scenarios;
  }

  @Watch("localValue", { deep: true })
  private watchLocalValue() {
    this.emitChangedValue();
  }

  @Watch("localValue.scenarios", { deep: true })
  private watchLocalValueScenarios() {
    this.localValue.sort = this.localValue.scenarios.map(
      (scenario) => scenario.id
    );
  }

  handleSave() {
    if (!this.form.validate()) {
      return;
    }

    this.emitSave();
  }

  handleDeleteScenario(scenarioId: number) {
    this.localValue.scenarios = this.localValue.scenarios.filter(
      (item) => item.id !== scenarioId
    );
  }

  @Emit("save") emitSave(): BatchRequestModel {
    return this.localValue;
  }

  @Emit("input") emitChangedValue(): BatchRequestModel {
    return this.localValue;
  }
}
