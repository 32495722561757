import ScenarioResponseModel from "./ScenarioResponseModel";

export default class BatchRequestModel {
  constructor(
    public id?: number,
    public name = "",
    public description = "",
    public sort: Array<number> = [],
    public executions: Array<number> = [],
    public scenarios: Array<ScenarioResponseModel> = []
  ) {}

  static ofSource(model: BatchRequestModel): BatchRequestModel {
    return new BatchRequestModel(
      model.id,
      model.name,
      model.description,
      model.sort,
      model.executions,
      model.scenarios
    );
  }
}
